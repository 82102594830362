import type { FunctionComponent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  href: string;
};

export const AvatarMenuLink: FunctionComponent<PropsWithChildren<Props>> = (
  props
) => {
  return (
    <Link
      to={props.href}
      reloadDocument={true}
      target={'_blank'}
      style={{
        width: '100%',
      }}
    >
      {props.children}
    </Link>
  );
};
