import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { PageHeader, NavigationMenu } from '@marketcast/ui-kit';
import { Helmet } from 'react-helmet-async';
import { getUserSelector } from '@mcrp/web-api';
import { useAppSelector } from '../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { AvatarMenu } from './AvatarMenu';
import { isEndpointActive } from './utils';

type Props = {
  title?: string;
};

export const Header: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const user = useAppSelector(getUserSelector);

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (destination: string) => {
      navigate(destination);
    },
    [navigate]
  );

  const handleLogoClick = useCallback(() => navigate('/ads'), [navigate]);

  const items = useMemo(
    () => [
      {
        title: 'Ads',
        isActive: isEndpointActive(location.pathname, 'ads'),
        onClick: () => handleNavigation('/ads'),
      },
      {
        title: 'Metadata',
        isActive: isEndpointActive(location.pathname, 'metadata'),
        onClick: () => handleNavigation('/metadata'),
      },
    ],
    [location.pathname]
  );

  if (!user) {
    return null;
  }

  return (
    <PageHeader onLogoClick={handleLogoClick}>
      <Helmet>
        <title>
          {props.title
            ? `${props.title} | Marketcast Media Management`
            : 'Marketcast Media Management'}
        </title>
      </Helmet>
      <NavigationMenu items={items} />
      <AvatarMenu user={user} />
    </PageHeader>
  );
};
