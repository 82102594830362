import type { FunctionComponent } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { AvatarMenu as AvatarMenuBase } from '@marketcast/ui-kit';
import { AvatarMenuLink } from './AvatarMenuLink';
import { useAppDispatch } from '../../../hooks';
import { signOut } from '@mcrp/web-api';
import type { Identity } from '@mcrp/web-api';

type Props = { user: Identity };

export const AvatarMenu: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    void dispatch(signOut());
  };

  return (
    <AvatarMenuBase name={props.user.name}>
      <MenuItem>
        <AvatarMenuLink
          href={
            'https://productsupport.marketcast.com/hc/en-us/requests/new?ticket_form_id=16719759647764'
          }
        >
          {'Report an Issue'}
        </AvatarMenuLink>
      </MenuItem>
      <MenuItem>
        <AvatarMenuLink
          href={
            'https://productsupport.marketcast.com/hc/en-us/requests/new?ticket_form_id=16719790720404'
          }
        >
          {'Request a Feature'}
        </AvatarMenuLink>
      </MenuItem>
      <MenuItem>
        <AvatarMenuLink
          href={
            'https://marketcastllc.atlassian.net/wiki/spaces/Ops/pages/2038464970/Welcome+to+the+MarketCast+Research+Platform+Survey+Builder+Resource+Site.'
          }
        >
          {'Learning Resources'}
        </AvatarMenuLink>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>{'Sign Out'}</MenuItem>
    </AvatarMenuBase>
  );
};
